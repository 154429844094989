import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import Loading from './Loading';
import searchImage from '../assets/images/search.png'
import cancelImage from '../assets/images/cancel.png'

const TOTAL_PAGES = 604;

const BookViewer = () => {
    const [currentPage, setCurrentPage] = useState(0);
    const [currentImage, setCurrentImage] = useState(null);
    const [showPageInput, setShowPageInput] = useState(false);
    const [pageInput, setPageInput] = useState('');
    const [showIcon, setShowIcon] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const loadImage = async (page) => {
            setIsLoading(true);
            try {
                const image = await import(`../assets/images/quran/quran-${page + 1}-fa.jpg`);
                setCurrentImage(image.default);
            } catch (error) {
                console.error(`Error loading image for page ${page + 1}:`, error);
            } finally {
                setIsLoading(false);
            }
        };

        loadImage(currentPage);
    }, [currentPage]);

    const handlers = useSwipeable({
        onSwipedLeft: () => changePage(-1),
        onSwipedRight: () => changePage(1),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });

    const changePage = (direction) => {
        setIsLoading(true);
        setCurrentPage((prevPage) => {
            let newPage = prevPage + direction;
            if (newPage < 0) newPage = TOTAL_PAGES - 1;
            if (newPage >= TOTAL_PAGES) newPage = 0;
            return newPage;
        });
    };

    const goToPage = () => {
        const pageNumber = parseInt(pageInput, 10) - 1;
        if (pageNumber >= 0 && pageNumber < TOTAL_PAGES) {
            setCurrentPage(pageNumber);
            setShowPageInput(false);
            setPageInput('');
        } else {
            alert(`شماره صفحه معتبر نیست. لطفاً عددی بین 1 تا ${TOTAL_PAGES} وارد کنید.`);
        }
    };

    const handleShowIcon = () => {
        setShowIcon(true);
        setTimeout(() => {
            setShowIcon(false);
        }, 5000);
    };

    return (
        <>
            {isLoading && <Loading />}
            <div className="book-viewer" {...handlers} onClick={handleShowIcon}>
                {currentImage && !isLoading && (
                    <img src={currentImage} alt={`Page ${currentPage + 1}`} className="book-page" />
                )}


                {showIcon && (
                    <div className="page-jump-icon" onClick={() => setShowPageInput(true)}>
                        <img
                            className="imgS"
                            src={searchImage}
                            alt="search"
                        />
                    </div>
                )}

                {showPageInput && (
                    <div className="page-input-overlay">
                        <div className="page-input-container">
                            <div className='cancel-pop'>
                                <img
                                    className="imgS"
                                    src={cancelImage}
                                    alt="cancel"
                                    onClick={() => setShowPageInput(false)}
                                />
                            </div>
                            <input
                                type="number"
                                value={pageInput}
                                onChange={(e) => setPageInput(e.target.value)}
                                placeholder="شماره صفحه را وارد کنید"
                                min="1"
                                max={TOTAL_PAGES}
                            />
                            <button onClick={goToPage}>برو</button>
                        </div>
                    </div>
                )}

                <button className="prev-page-button" onClick={() => changePage(1)}>صفحه بعد</button>
                <button className="next-page-button" onClick={() => changePage(-1)}>صفحه قبل</button>

            </div>
        </>
    );
};

export default BookViewer;
