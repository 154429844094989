import './App.css';
import BookViewer from './components/BookViewer';

function App() {
  return (
    <div className="App">
            <BookViewer />
    </div>
  );
}

export default App;
